@tailwind base;
@tailwind components;
@tailwind utilities;


.video-js .vjs-big-play-button {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 10; /* Ensure it is above other elements */
    display: block;
  }

  .MuiTooltip-tooltip {
    max-width: 600px !important;
    white-space: normal;
  }